import axios from "axios";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { SISE_AUTH } from "../actions/types";
import settings from "../settings";
import { AUTOS, HOGAR, VIDA, SALUD } from "../utils/risksTypes";

export const getYearsAPI = createAsyncAction("GET_YEARS", async () => {
    const res = await fetch(`${settings.quotationUrlApi}catalog/years`)
        .then((response) => validateResponse(response))
        .catch((error) => {
            throw exeptionCodeResponse(error);
        });
    return res;
});

export const getBrandsAPI = createAsyncAction("GET_BRANDS", async (year) => {
    const res = await fetch(`${settings.quotationUrlApi}catalog/brand/${year}`)
        .then((response) => validateResponse(response))
        .catch((error) => {
            throw exeptionCodeResponse(error);
        });
    return res;
});

export const getModelsAPI = createAsyncAction(
    "GET_MODELS",
    async (brand, year) => {
        const res = await fetch(
            `${settings.quotationUrlApi}catalog/model/${brand}/${year}`
        )
            .then((response) => validateResponse(response))
            .catch((error) => {
                throw exeptionCodeResponse(error);
            });
        return res;
    }
);

export const getVersionsAPI = createAsyncAction(
    "GET_VERSIONS",
    async (model, year) => {
        const res = await fetch(
            `${settings.quotationUrlApi}catalog/version/${model}/${year}`
        )
            .then((response) => validateResponse(response))
            .catch((error) => {
                throw exeptionCodeResponse(error);
            });
        return res;
    }
);

export const getAccesoriesAPI = createAsyncAction(
    "GET_ACCESORIES",
    async () => {
        const res = await fetch(`${settings.quotationUrlApi}catalog/accesories`)
            .then((response) => validateResponse(response))
            .catch((error) => {
                throw exeptionCodeResponse(error);
            });
        return res;
    }
);

export const postSaveQuotationAPI = createAsyncAction(
    "POST_SAVE_QUOTATION",
    async (bodyReq, riskType) => {
        const headers = { "Content-Type": "application/json" };
        const body = JSON.stringify(bodyReq);
        try {
            const response = await axios.post(
                `${settings.quotationUrlV2Api}quotation/publicquotationV2`,
                body,
                { headers }
            );
            return response.data;
        } catch (error) {
            return error;
        }
    }
);

export const getQuotationAndCreatePolicyRequestAPI = createAsyncAction(
    "GET_SAVE_POLICY_REQUEST_ID",
    async (quotationId, idProduct) => {
        const headers = { "Content-Type": "application/json" };
        const body = JSON.stringify({
            quotationId,
            idProduct,
        });
        const res = await fetch(
            `${settings.quotationUrlApi}quotation/getQuotationAndCreatePolicyRequest/`,
            { method: "POST", headers, body }
        )
            .then((response) => validateResponse(response))
            .catch((error) => {
                throw exeptionCodeResponse(error);
            });
        return res;
    }
);

export const getQuotationAndCreateProposalAPI = async (quote) => {
    const headers = { "Content-Type": "application/json" };
    const body = JSON.stringify(quote);
    const res = await fetch(`${settings.quotationUrlApi}quotation/getQuotationAndCreateProposal`, { method: 'POST', headers, body })
        .then((response) => validateResponse(response))
        .catch((error) => {
            throw exeptionCodeResponse(error);
        });
    return res;
};

export const updateProposalToPolicyAPI = async (quote) => {
    const headers = { "Content-Type": "application/json" };
    const body = JSON.stringify(quote);
    const res = await fetch(`${settings.quotationUrlApi}policyrequest/updateProposalToPolicy`, { method: 'PUT', headers, body })
        .then((response) => validateResponse(response))
        .catch((error) => {
            throw exeptionCodeResponse(error);
        });
    return res;
};

export const putUpdateQuotationAPI = createAsyncAction(
    "POST_UPDATE_QUOTATION",
    async (bodyReq) => {
        const headers = { "Content-Type": "application/json" };
        const body = JSON.stringify(bodyReq);
        const res = await fetch(`${settings.quotationUrlApi}quotation/update`, {
            method: "PUT",
            headers,
            body,
        })
            .then((response) => validateResponse(response))
            .catch((error) => {
                throw exeptionCodeResponse(error);
            });
        return res;
    }
);

export const putUpdateQuotationScreenValuesAPI = createAsyncAction(
    "POST_UPDATE_QUOTATION_SCREEN_VALUES",
    async (bodyReq) => {
        const headers = { "Content-Type": "application/json" };
        const body = JSON.stringify(bodyReq);
        const res = await fetch(
            `${settings.quotationUrlApi}quotation/updateScreenValues`,
            { method: "PUT", headers, body }
        )
            .then((response) => validateResponse(response))
            .catch((error) => {
                throw exeptionCodeResponse(error);
            });
        return res;
    }
);

export const sendLinkQuotationMailAPI = async (quotationId, mail, form) => {
    const headers = { "Content-Type": "application/json" };
    const body = JSON.stringify({ quotationId, mail, form });
    const url = `${settings.quotationUrlApi}contact/quotation/sendLink`;
    await fetch(url, { method: "POST", headers, body })
        .then((response) => validateResponse(response))
        .catch((error) => {
            throw exeptionCodeResponse(error);
        });
};

const validateResponse = (response) => {
    if (response.ok) {
        return response.json();
    } else {
        throw exeptionCodeResponse();
    }
};

const exeptionCodeResponse = (error = "error") => {
    return error;
};

export const getFieldsConfigurationByRiskType = async (riskType) => {
    const url = `${settings.quotationUrlApi}fieldsconfiguration/risktype/${riskType}`;

    try {
        const response = await fetch(url);
        return validateResponse(response);
    } catch (error) {
        throw exeptionCodeResponse(error);
    }
};

export const getFieldsConfigurationById = async (id) => {
    const url = `${settings.quotationUrlApi}fieldsconfiguration/${id}`;

    try {
        const response = await fetch(url);
        return validateResponse(response);
    } catch (error) {
        throw exeptionCodeResponse(error);
    }
};

export const getFieldsConfigurationByInsuranceId = async (insuranceId, id) => {
    const url = `${settings.quotationUrlApi}fieldsconfiguration/${insuranceId}/${id}`;

    try {
        const response = await fetch(url);
        return validateResponse(response);
    } catch (error) {
        throw exeptionCodeResponse(error);
    }
};

export const getQuotationByIdAPI = createAsyncAction(
    "GET_QUOTATION_BY_ID",
    async (quotationId) => {
        const headers = { "Content-Type": "application/json" };
        const res = await fetch(
            `${settings.quotationUrlApi}quotation/${quotationId}`,
            { method: "GET", headers }
        )
            .then((response) => validateResponse(response))
            .catch((error) => {
                throw exeptionCodeResponse(error);
            });
        return res;
    }
);

export const getValueIMC = async (medicalInformation) => {
    const { weight, height } = medicalInformation;
    const headers = { "Content-Type": "application/json" };
    const res = await fetch(
        `${settings.quotationUrlV2Api}quotation/calcule/imc?weight=${weight}&height=${height}`,
        { method: "GET", headers }
    )
        .then((response) => validateResponse(response))
        .catch((error) => {
            throw exeptionCodeResponse(error);
        });
    return res;
};

export const isTecnoredRequired = async (ramo, plan) => {
    const headers = { "Content-Type": "application/json" };
    const res = await fetch(
        `${settings.listsUrlApi}TecnoredRequired?cod_ramo=${ramo}&plan_tecnico=${plan}`,
        { method: "GET", headers }
    )
        .then((response) => validateResponse(response))
        .catch((error) => {
            throw exeptionCodeResponse(error);
        });
    
    return res?.tecnoredRequired?.result[0]?.sn_tecnored === 1;
};

export const quotationTecnored = async (quoteBody) => {
    const headers = { "Content-Type": "application/json" };
    const body = JSON.stringify(quoteBody);

    const res = await fetch(
        `${settings.quotationUrlApi}tecnored`,
        { method: "POST", headers, body }
    )
        .then((response) => validateResponse(response))
        .catch((error) => {
            throw exeptionCodeResponse(error);
        });
    
    return res;
};

export const quotationSiseTecnored = async (quoteBody) => {
    const headers = { "Content-Type": "application/json" };
    const body = JSON.stringify(quoteBody);

    const res = await fetch(
        `${settings.quotationUrlApi}quotation/siseTecnored`,
        { method: "POST", headers, body }
    )
        .then((response) => validateResponse(response))
        .catch((error) => {
            throw exeptionCodeResponse(error);
        });
    
    return res;
};

export const sendQuotationMail = async (data) => {
    const headers = { "Content-Type": "application/json" };
    const body = JSON.stringify(data);
    const url = `${settings.quotationUrlApi}contact/quotation/send`;
    await fetch(url, { method: "POST", headers, body })
        .then((response) => validateResponse(response))
        .catch((error) => {
            throw exeptionCodeResponse(error);
        });
};

export const authSiseUserApi = createAsyncAction(
    SISE_AUTH,
    async () => {
        const res = await fetch(`${settings.authUrlBase}sise/token`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                return response.text();
            })
            .then((json) => {
                localStorage.setItem("sise:token", json);
                return json;
            })
            .catch((error) => {
                throw exeptionCodeResponse(error);
            });

        return res;
    }
);

export const verifyUserStatus = async (data) => {
    const token = localStorage.getItem("sise:token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };
    const body = JSON.stringify({
        queryId: settings.siseBlacklist,
        filters: [],
        complete: false,
        isLink: false,
        parameters: [
            {
                name: "e_nombre1",
                type: "string",
                value: "",
                requireOnExecution: false,
                hiddenOnExecution: false,
            },
            {
                name: "e_nombre2",
                type: "string",
                value: "",
                requireOnExecution: false,
                hiddenOnExecution: false,
            },
            {
                name: "e_apellido1",
                type: "string",
                value: "",
                requireOnExecution: false,
                hiddenOnExecution: false,
            },
            {
                name: "e_apellido2",
                type: "string",
                value: "",
                requireOnExecution: false,
                hiddenOnExecution: false,
            },
        ].map((p) => {
            const [firstName, ...secondName] = data.name.split(" ");
            const [firstSurname, ...secondSurname] = data.surname.split(" ");
            if (p.name === "e_nombre1") {
                return {
                    ...p,
                    value: firstName || "",
                };
            }

            if (p.name === "e_nombre2") {
                return {
                    ...p,
                    value: secondName.join(" ") || "",
                };
            }

            if (p.name === "e_apellido1") {
                return {
                    ...p,
                    value: firstSurname || "",
                };
            }

            if (p.name === "e_apellido2") {
                return {
                    ...p,
                    value: secondSurname.join(" ") || "",
                };
            }

            return p;
        }),
    });

    const res = await fetch(`${settings.siseUrlApi}query/execute`, {
        method: "POST",
        headers,
        body,
    })
        .then((response) => validateResponse(response))
        .catch((error) => {
            throw exeptionCodeResponse(error);
        });
    return res;
};

export const verifyPolicy2G = async () => {
    const token = localStorage.getItem("sise:token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };
    const body = JSON.stringify({
        queryId: settings.siseReservado,
        filters: [],
        complete: false,
        isLink: false,
        parameters: [
            {
                name: "cod_ramo",
                type: "int",
                value: 37,
                requireOnExecution: false,
                hiddenOnExecution: false,
            },
        ],
    });

    const res = await fetch(`${settings.siseUrlApi}query/execute`, {
        method: "POST",
        headers,
        body,
    })
        .then((response) => validateResponse(response))
        .catch((error) => {
            throw exeptionCodeResponse(error);
        });
    return res;
};

export const verifyPatent = async (patent = null, chasis = null, motor = null) => {
    const token = localStorage.getItem("sise:token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };
    const body = JSON.stringify({
        queryId: settings.siseVerifyPatent,
        filters: [],
        complete: false,
        isLink: false,
        parameters: [
            {
                name: "txt_patente",
                type: "string",
                value: patent,
                requireOnExecution: false,
                hiddenOnExecution: false,
            },
            {
                name: "txt_chasis",
                type: "string",
                value: chasis,
                requireOnExecution: false,
                hiddenOnExecution: false,
            },
            {
                name: "txt_motor",
                type: "string",
                value: motor,
                requireOnExecution: false,
                hiddenOnExecution: false,
            },
        ],
    });

    const res = await fetch(`${settings.siseUrlApi}query/execute`, {
        method: "POST",
        headers,
        body,
    })
        .then((response) => validateResponse(response))
        .catch((error) => {
            throw exeptionCodeResponse(error);
        });
    
    return res?.result?.result[0]?.existe === 1;
};

export const generateLicenseNumber = async (policyNumber, hasWife, numberChildren) => {
    const token = localStorage.getItem("sise:token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };
    const body = JSON.stringify({
        queryId: settings.siseGenerateLicenseNumber,
        filters: [],
        complete: false,
        isLink: false,
        parameters: [
            {
                name: "nro_pol",
                type: "string",
                value: policyNumber,
                hiddenOnExecution: false,
                requireOnExecution: false
            },
            {
                name: "sn_conyugue",
                type: "int",
                value: hasWife ? 1 : 0,
                hiddenOnExecution: false,
                requireOnExecution: false
            },
            {
                name: "cantidad_hijos",
                type: "int",
                value: numberChildren,
                hiddenOnExecution: false,
                requireOnExecution: false
            }
        ]
    });

    const res = await fetch(`${settings.siseUrlApi}query/execute`, {
        method: "POST",
        headers,
        body,
    })
        .then((response) => validateResponse(response))
        .catch((error) => {
            throw exeptionCodeResponse(error);
        });
    return res;
};

export const getStateList = async () => {
    const token = localStorage.getItem("sise:token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };
    const body = JSON.stringify({
        queryId: settings.siseStateList,
        filters: [],
        complete: false,
        isLink: false,
        parameters: [],
    });

    const res = await fetch(`${settings.siseUrlApi}query/execute`, {
        method: "POST",
        headers,
        body,
    })
        .then((response) => validateResponse(response))
        .catch((error) => {
            throw exeptionCodeResponse(error);
        });
    return res;
};

export const getPostalCodesByState = async (code) => {
    const headers = { 'Content-Type': 'application/json' };

    const res = await fetch(`${settings.listsUrlApi}PostalCodes?codigo_provincia=${code}&calle=0&nro=0`, { method: 'GET', headers })
        .then(response => response.json())
        .then(data => {
            if (data.postalCode)
                return data.postalCode.result;
            
            return [];
        })
        .catch((error) => {
            throw exeptionCodeResponse(error);
        });

    return res;
};

export const getComercialPlansLabels = async (agentCode, validityType, riskType) => {
    const headers = { 'Content-Type': 'application/json' };

    const res = await fetch(`${settings.internalListsUrlApi}commercialproductsbybranchagentvaliditytype?PrefixId=${riskType}&AgentTypeId=4&AgentId=${agentCode}&ValidityTypeId=${validityType}`, { method: 'GET', headers })
        .then(response => response.json())
        .then(data => {
            if (data.EsValido)
                return data.DatosAdicionales;
            
            return [];
        })
        .catch((error) => {
            throw exeptionCodeResponse(error);
        });

    return res;
}


export const downloadPDF = async (data) => {
    const headers = { "Content-Type": "application/json" };
    const blobHeaders = { "Content-Type": "application/pdf" };
    const res = await fetch(`${settings.quotationUrlApi}contact/download`, {
        method: "POST",
        headers,
        body: JSON.stringify(data),
    })
        .then((response) => validateResponse(response))
        .catch((error) => {
            throw exeptionCodeResponse(error);
        });

    const blob = await fetch(
        `${settings.quotationUrlBase}templates/${res.data.path}.pdf`,
        { method: "GET", headers: blobHeaders }
    ).then((response) => response.blob());

    return {
        ...res,
        blob,
    };
};
